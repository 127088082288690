.app {
  padding: 20px;
  overflow: auto;
  width: 100vw;
  height: calc(100vh - 24px);
  min-height: 650px;
}

.source-code-link {
  position: absolute;
  bottom: 0;
  left: 20px;
}
